import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

//reducer
import {
  // setBookingTime,
  setBookingDate,
  setBookingTime,
} from "../../../../../reducer/clientsSlice";
//component
import Weekdays from "./weekdays";
import Slots from "../slots";
import { getSlotsCountByDate } from "../../../../../services/client";
import { setLoading, setLoadingContent, setLoadingText } from "../../../../../reducer/auth/auth";
import { removFirstDash } from "../../../../../utils/helpers";
import { el } from "date-fns/locale";
import { useSearchParams } from "react-router-dom";

export default function AdvanceCalendar({
  mediumItems,
  practitionerInfo,
  user_type,
  client_id = "",
  activesession_id = "5",
  sessionType,
  activeSubSessionId,
  activeDurationId,
  is_group_session,
  bookingType
  , noSlotsFound,
  setnoSlotsFound,
  type
}) {
  //hooks
  const dispatch = useDispatch();
  const { id, timezone } = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);

  const [params] = useSearchParams();
  const [monthYear, setMonthYear] = useState([]);
  const [dateObject, setdateObject] = useState(moment());
  // const [allmonths, setallmonths] = useState(moment.months());
  // const [showYearTable, setshowYearTable] = useState(false);
  // const [showMonthTable, setshowMonthTable] = useState(false);
  // const [showDateTable, setshowDateTable] = useState(true);
  const [selectedDay, setselectedDay] = useState(null);
  const [clickedData, setclickedData] = useState();
  const [slots, setSlots] = useState({});
  const [slotsupdated, setSlotsUpdated] = useState(false);
  const [clickedDate, setclickedDate] = useState();

  ///
  const [oldStartDate, setOldStartDate] = useState('');
  const [oldEndDate, setOldEndDate] = useState('');
  const [activeMonth, setActiveMonth] = useState(moment().month());
  const [month_empty, setmonth_empty] = useState(false);
  //
  // const [statusHighlighted, setstatusHighlighted] = useState(false)
  // const [dataList, setDataList] = useState([]);
  const firstDayOfMonth = () => {
    let firstDay = moment(dateObject)
      .startOf("month")
      .format("d");
    return firstDay;
  };

  const getTodaysDate = (day) => {
    var mydate = `${day}-${month()}-${year()}`;
    return removFirstDash(moment(mydate).format("YYYY-MM-DD"));
  };
  const daysInMonth = () => {
    return dateObject.daysInMonth();
  };
  const year = () => {
    return dateObject.format("Y");
  };
  const month = () => {
    return dateObject.format("MMMM");
  };
  const shortmonth = () => {
    return dateObject.format("MMM");
  };
  const currentDay = () => {
    return dateObject.format("D");
  };
  const currentMonth = () => {
    return moment().format("MMM");
  };
  const getWeekDayName = (d, monthName, yearName) => {
    var mydate = `${d}-${monthName}-${yearName}`;
    var weekDayName = moment(mydate).format("dddd");
    return weekDayName;
  };
  const changeDateFormat = (d) => {
    let monthName = month();
    let yearName = year();
    var mydate = `${d}-${monthName}-${yearName}`;
    var date = moment(mydate).format("YYYY-MM-DD");
    return date;
  };
  // const showMonth = (e, month) => {
  //   setshowMonthTable(!showMonthTable);
  //   setshowDateTable(!showDateTable);
  // };

  // const setMonth = (month) => {
  //   let monthNo = allmonths.indexOf(month);
  //   let dateObjec = Object.assign({}, dateObject);
  //   dateObjec = moment(dateObject).set("month", monthNo);
  //   setdateObject(dateObjec);
  //   setshowMonthTable(!showMonthTable);
  //   setshowDateTable(!showDateTable);
  // };

  const onPrev = () => {
    // activeMonth,setActiveMonth
    console.log('working');
    let name = dateObject.month();
    let currentMonth = moment().month();
    let yearname = moment().year();
    let yearN = dateObject.year();

    if (name <= currentMonth && yearname === yearN) {
      return;
    }
    setSlotsUpdated(false);
    setclickedData();
    let dataes;
    // Array.from(document.querySelectorAll(".td-clicked")).forEach(function (el) {
    //   el.classList.remove("td-clicked");
    // });
    let curr = "month";
    setdateObject(dateObject.subtract(1, curr));

    console.log(name - 1, "name")
    console.log(currentMonth, "currentMonth")
    if (name - 1 === currentMonth) {
      //   dataes = {
      //     start: moment(dateObject)
      //       .startOf("month")
      //       .format("YYYY-MM-DD"),
      //     end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"),
      //   };
      // }
      const endOfMonth = moment().endOf('month').format('DD');
      const startOfMonth = moment().startOf('month').format('DD');
      const presentOfMonth = moment().format('DD');
      let endDate;

      if (bookingType === "professional" || user_type == "medium" || bookingType == "outreach") {
        endDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("YYYY-MM-DD");
        setOldEndDate(moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD"))
        window.oldEndDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD")
      }
      else {


        if (presentOfMonth <= 15) {
          endDate = moment().add('days', 15).format("YYYY-MM-DD");
          setOldEndDate(moment().add('days', 15).format("DD"));
          window.oldEndDate = moment().add('days', 15).format("DD")
        }
        else {
          endDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("YYYY-MM-DD");
          setOldEndDate(moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD"))
          window.oldEndDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD")
        }
      }
      dataes = {
        start: moment().format("YYYY-MM-DD"),
        end: endDate
      }
      setOldStartDate(moment().format("DD"))
      window.oldStartDate = moment().format("DD")


    }
    else {
      if (bookingType === "professional" || user_type == "medium" || bookingType == "outreach") {
        dataes = {
          start: moment(`${1}-${month()}-${year()}`).format("YYYY-MM-DD"),
          end: moment(dateObject)
            .endOf("month")
            .format("YYYY-MM-DD")
        };
      }
      else {


        dataes = {
          start: moment(`${1}-${month()}-${year()}`).format("YYYY-MM-DD"),
          // start: moment(dateObject)
          // .startOf("month")
          // .format("YYYY-MM-DD"),
          end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"),
        };
      }
    }

    // setActiveMonth(moment().subtract(1, curr).month())
    // console.log(name, "name");
    // console.log(activeMonth, "prevMon");
    // if ( name + 1 > activeMonth + 1) {
    //   return;
    // }


    getSlotsCounts(dataes);
    // fillTbale();
  };
  const onNext = () => {
    // console.log(activeMonth, "prevMon");
    setSlotsUpdated(false);

    setclickedData();
    // var element = document.getElementById("calendar");
    // console.log(element,element,"element")

    let curr = "month";
    // setActiveMonth(moment().add(1, curr).month())
    setdateObject(dateObject.add(1, curr));
    let dataes
    if (bookingType === "professional" || user_type == "medium" || bookingType == "outreach") {
      dataes = {
        start: moment(dateObject)
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment(dateObject)
          .endOf("month")
          .format("YYYY-MM-DD")
        // end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"),
      };
      setOldStartDate(moment(dateObject).startOf("month").format("DD"))
      window.oldStartDate = moment(dateObject).startOf("month").format("DD")
    }
    else {


      dataes = {
        start: moment(dateObject)
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"),
        // end: moment(dateObject)
        //   .endOf("month")
        //   .format("YYYY-MM-DD"),
      };

      //
      setOldEndDate(moment(`${15}-${month()}-${year()}`).format("DD"));
      window.oldEndDate = moment(`${15}-${month()}-${year()}`).format("DD")
      // console.log(moment(dateObject).year(), "selectedDay");
      // console.log(moment(dateObject).month(), "selectedDaym");
      // console.log(moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"))
      // console.log(moment(dateObject)
      // .endOf("month")
      // .format("YYYY-MM-DD"))
    }
    setOldStartDate(moment(dateObject).startOf("month").format("DD"))
    window.oldStartDate = moment(dateObject).startOf("month").format("DD")
    getSlotsCounts(dataes);
    // fillTbale();
  };

  // const setYear = (year) => {
  //   let dateObjects = Object.assign({}, dateObject);
  //   dateObjects = moment(dateObjects).set("year", year);
  //   setdateObject(dateObjects);
  //   setshowMonthTable(!showMonthTable);
  //   setshowYearTable(!showYearTable);
  // };
  // const getDates = (startDate, stopDate) => {
  //   var dateArray = [];
  //   var currentDate = moment(startDate);
  //   var stopDate = moment(stopDate);
  //   while (currentDate <= stopDate) {
  //     dateArray.push(moment(currentDate).format("YYYY"));
  //     currentDate = moment(currentDate).add(1, "year");
  //   }
  //   return dateArray;
  // };
  const getClickedDate = (d) => {
    let monthName = shortmonth();
    let yearName = year();
    let weekN = getWeekDayName(d, monthName, yearName);
    return `${weekN}, ${monthName} ${d}`;
  };

  const onDayClick = (e, d, todayDate, clicked) => {
    window.firstClick = true
    if (d !== window.clickedDates) {
      setSlotsUpdated(false);
    }
    window.clickedDates = d
    setclickedDate(d)
    Array.from(document.querySelectorAll(".td-clicked")).forEach(function (el) {
      el.classList.remove("td-clicked");
    });
    let clickedDate = window.clickedDate;
    document.getElementById(d).className = "td-clicked";
    setclickedData(d);
    dispatch(setBookingDate(changeDateFormat(d)));
    setselectedDay(getClickedDate(d));

    // if (currentMonth() !== shortmonth()) {
    // if (clickedDate === undefined || (d > 15 && clickedDate < 16)) {
    //   if (d > 15 && (clicked == undefined || clicked <= 15)) {
    //     let dataes = {
    //       start: moment(`${16}-${month()}-${year()}`).format("YYYY-MM-DD"),
    //       end: moment(dateObject)
    //         .endOf("month")
    //         .format("YYYY-MM-DD"),
    //     };
    //     getSlotsCounts(dataes, d);
    //   }
    // }

    // if (clickedDate === undefined || (d < 16 && clickedDate > 15)) {
    //   if (clicked !== undefined && clicked > 15) {
    //     let dataes = {
    //       start: moment(dateObject)
    //         .startOf("month")
    //         .format("YYYY-MM-DD"),
    //       end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD"),
    //     };
    //     getSlotsCounts(dataes, d);
    //   }
    // }
    if (d > parseInt(window.oldEndDate)) {
      let start = parseInt(window.oldStartDate);
      let dataes = {
        start: moment(`${start}-${month()}-${year()}`).format("YYYY-MM-DD"),
        end: moment(`${d}-${month()}-${year()}`).format("YYYY-MM-DD")
      };
      ///
      setOldStartDate(moment(`${start}-${month()}-${year()}`).format("DD"))
      window.oldStartDate = moment(`${start}-${month()}-${year()}`).format("DD")
      //
      setOldEndDate(moment(`${d}-${month()}-${year()}`).format("DD"));
      window.oldEndDate = moment(`${d}-${month()}-${year()}`).format("DD")
      getSlotsCounts(dataes, d);
    }

  };

  const getSlotsCounts = (dates, d) => {
    dispatch(setLoadingContent(`<div>Searching for availability... we'll have the results shortly! </div>`));
    dispatch(setLoadingText(true));
    // dispatch(setLoading(true));
    let body;
    let data = activesession_id;
    let getmed = "";
    if (practitionerInfo !== null && practitionerInfo !== undefined) {
      if (practitionerInfo.general.payStatus !== undefined) {
        if (practitionerInfo.general.payStatus === "before") {
          data = "8";
        }
        else {
          data = "7";
        }
      }
      else {

        if (
          (practitionerInfo.general.payType.includes(1) &&
            practitionerInfo.general.payType.includes(2)) ||
          (practitionerInfo.general.payType.length === 1 &&
            practitionerInfo.general.payType.includes(1)) ||
          practitionerInfo.general.payType.length === 0
        ) {
          data = "8";
        } else if (
          practitionerInfo.general.payType.length === 1 &&
          practitionerInfo.general.payType.includes(2)
        ) {
          data = "7";
        }
      }
    }
    if (auth.role === "admin" || (type == "admin" && auth.role === "client") || (type == "admin" && auth.role === "medium")) {
      getmed = params.get("id");
    }
    else if (auth.role === "medium") {
      getmed = id;
    }
    console.log(user_type, "user_type")
    if (user_type == "") {
      body = {
        startDate: dates.start,
        endDate: dates.end,
        medium_id: getmed,
        session_id: activesession_id,
        user_type: user_type,
        subsession_id: activeSubSessionId === null ? "" : activeSubSessionId,
        client_id: client_id

      };
    }
    else if (user_type === "medium") {
      console.log('medium');
      body = {
        startDate: dates.start,
        endDate: dates.end,
        medium_id: getmed,
        session_id: activesession_id,
        user_type: user_type,
        subsession_id: activeSubSessionId === null ? "" : activeSubSessionId,
        client_id: client_id

      };
    } else {
      body = {
        startDate: dates.start,
        endDate: dates.end,
        session_id: sessionType == "prof" ? data : activesession_id,
        medium_id:
          sessionType == "prof"
            ? practitionerInfo !== null && practitionerInfo.general.medium_id
            : getmed,
        subsession_id: activeSubSessionId === null ? "" : activeSubSessionId

      };
    }

    getSlotsCountByDate(body, is_group_session)
      .then((data) => {
        dispatch(setLoadingContent(``));
        dispatch(setLoadingText(false));
        // dispatch(setLoading(false));
        if (data.data.status) {
          setmonth_empty(data.data.externalInfo.month_empty)
          if (data.data.externalInfo.month_empty) {
            window.scrollTo({
              left: 0,
              top: 700,
              behavior: "smooth",
            });
          }
          let info = data.data.data;
          setSlots(data.data.data);
          setSlotsUpdated(false);
          setclickedData(d);
          window.clickedDate = d;
          fillTbale(info, d);
        }
      })
      .catch(() => {
        dispatch(setLoadingContent(``));
        dispatch(setLoadingText(false));
        // dispatch(setLoading(false));
      });
  };
  const showCount = (slot, date) => {
    return slot[date];
  };

  const checkCalendarType = (d, currentDays, todayDate, otherDate, clicked) => {
    let status = false;
    // console.log(shortmonth())
    // console.log(currentMonth())
    // console.log(currentDays ,todayDate ,otherDate)
    // console.log(currentDay(), d);
    // console.log(todayDate, "todayDate");
    // const date = moment("2023-05-15"); // Thursday Feb 2015
    // console.log(date, "date");
    // const dow = date.day();
    // console.log(dow);
    // console.log(
    //   d,
    //   currentDays,
    //   todayDate,
    //   otherDate,
    //   clicked,
    //   "todayDatetodayDatetodayDate"
    // );
    // if (shortmonth() === currentMonth()) {
    //   if (parseInt(currentDay()) <= parseInt(d)) {
    //     status = true;
    //   } else {
    //     status = false;
    //   }
    // } else {



    // if (  parseInt(d) <= 15 && (clicked == undefined || clicked <= 15)) {
    //   status = true;
    // } else if (parseInt(d) >= 16 && clicked > 15) {
    //   status = true;
    // } else {
    //   status = false;
    // 
    if (d >= parseInt(window.oldStartDate) && d <= parseInt(window.oldEndDate)) {
      status = true
    }
    else {
      status = false
    }

    return status;
  };
  const fillTbale = (slot, clicked) => {
    let blanks = [];
    let rows = [];
    let cells = [];

    for (let i = 0; i < firstDayOfMonth(); i++) {
      blanks.push(<td className="calendar-day  empty">{""}</td>);
    }
    let daysInMonthList = [];
    for (let d = 1; d <= daysInMonth(); d++) {
      let date = getTodaysDate(d);
      let currentDays = d === currentDay() ? "today" : "";
      let todayDate = moment().format("YYYY-MM-DD");
      let otherDate = moment(`${d}-${month()}-${year()}`).format("YYYY-MM-DD");
      let circle = clicked === d ? "td-clicked" : "";
      if (d === currentDay()) {
        setselectedDay(getClickedDate(d));
      }
      console.log(shortmonth(), currentMonth(), " shortmonth() === currentMonth()")
      daysInMonthList.push(
        <td
          onClick={
            !moment(otherDate).isBefore(todayDate)
              ? (e) => onDayClick(e, d, todayDate, clicked)
              : () => {
                console.log("disabled");
              }
          }
          key={d}
          className={`${shortmonth() === currentMonth() ? '' : 'bg-pointer-selected'} date-picker calendar-td pointer    
           ${checkCalendarType(d, currentDays, todayDate, otherDate, clicked)
              ? `bg-pointer-selected ${checkMediumGroupSessionType() && showCount(slot, date) &&
                showCount(slot, date) !== undefined ? 'Green-bg' : ''}`
              : ""
            } ${!moment(otherDate).isBefore(todayDate) ? "" : "disabled-date"}`}
        >
          <div className={`pointer  ${circle}`}>
            {!checkMediumGroupSessionType() && slot !== undefined &&
              showCount(slot, date) !== 0 &&
              showCount(slot, date) !== undefined ? (
              <p className="orange-bulb">{showCount(slot, date)} </p>
            ) : (
              <></>
            )}
            <span
              id={d}
              className={`pointer ${!moment(otherDate).isBefore(todayDate) ? "" : "disabled-date"
                } ${currentDays ? "selected" : ""}
             ${parseInt(currentDay()) === parseInt(d) &&
                  shortmonth() === currentMonth()
                  ? "td-clicked-active-date"
                  : ""
                }`}
            >
              {d}
            </span>
          </div>
        </td>
      );
    }

    var totalSlots = [...blanks, ...daysInMonthList];
    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });
    setMonthYear(daysinmonth);
    const div = document.getElementsByClassName("td-clicked");
    if (div[0] !== undefined) {
      div[0].className = "";
    }
  };
  useEffect(() => {
    console.log(bookingType, "bookingType", "bookingType", "bookingType")

    let presentDay = moment().date();
    let dataes;
    // if (presentDay < 16) {
    //   dataes = {
    //     start: moment().format("YYYY-MM-DD"),
    //     //  moment(dateObject)
    //     //   .startOf("month")
    //     //   .format("YYYY-MM-DD"),
    //     end: moment(`${15}-${month()}-${year()}`).format("YYYY-MM-DD")
    //     // end: moment(dateObject)
    //     //   .endOf("month")
    //     //   .format("YYYY-MM-DD"),
    //   };
    // }
    // else {


    //   dataes = {
    //     // start: moment().format("YYYY-MM-DD"),
    //     start: moment(`${16}-${month()}-${year()}`).format("YYYY-MM-DD"),
    //     //  moment(dateObject)
    //     //   .startOf("month")
    //     //   .format("YYYY-MM-DD"),

    //     end: moment(dateObject)
    //       .endOf("month")
    //       .format("YYYY-MM-DD"),
    //   };
    // }


    const endOfMonth = moment().endOf('month').format('DD');
    const startOfMonth = moment().startOf('month').format('DD');
    const presentOfMonth = moment().format('DD');
    let endDate;


    if (bookingType === "professional" || user_type == "medium" || bookingType == "outreach") {
      endDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("YYYY-MM-DD");
      setOldEndDate(moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD"))
      window.oldEndDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD")
      setOldStartDate(moment().format("DD"))
      window.oldStartDate = moment().format("DD")
      dataes = {
        start: moment().format("YYYY-MM-DD"),
        end: endDate
      }
    }
    else {
      if (presentOfMonth <= 15) {
        endDate = moment().add('days', 15).format("YYYY-MM-DD");
        setOldEndDate(moment().add('days', 15).format("DD"));
        window.oldEndDate = moment().add('days', 15).format("DD")
      }
      else {
        endDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("YYYY-MM-DD");
        setOldEndDate(moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD"))
        window.oldEndDate = moment().add('days', parseInt(endOfMonth - presentOfMonth)).format("DD")
      }
      dataes = {
        start: moment().format("YYYY-MM-DD"),
        end: endDate
      }
      setOldStartDate(moment().format("DD"))
      window.oldStartDate = moment().format("DD")
    }
    // console.log(dataes);
    getSlotsCounts(dataes);
    // fillTbale()
  }, [timezone, activeSubSessionId]);

  // const getDate = (week, month, day) => {
  //   return `${week}, ${month} ${day}`;
  // };

  const checkMediumGroupSessionType = () => {
    if (user_type == "medium" && is_group_session === 1) {
      return true;
    }
    else {
      return false;
    }

  }
  return (
    <>
      <div className="col-md-12">
        <div className="page-heading">
          {/* <div className="page-hd">
            <h6>
              Select Date & Time
              <a href="#">
                Edit Preferences
                <img src="/images/pencil.png" />
              </a>
            </h6>
          </div> */}
        </div>
        <div className="calenl-scn">
          <div className="wrapper">
            <div className="container-calendar">
              <h3 id="monthAndYear">
                {month()}&nbsp;
                {year()}
              </h3>
              <div className="button-container-calendar">
                <button id="previous" onClick={onPrev}>
                  <img src="/images/black-erow.png" alt="previous" />
                </button>
                <button id="next" onClick={onNext}>
                  <img src="/images/right-ereow.png" alt="next" />
                </button>
              </div>

              <table className="table-calendar" id="calendar" data-lang="en">
                <thead id="thead-month">
                  <Weekdays />
                </thead>
                <tbody id="calendar-body">{monthYear}</tbody>
              </table>
            </div>
          </div>

          <div className="tim-clander-shedul">
            {slotsupdated && (
              <>
                <h4 className="font-size-24"><b>{selectedDay}</b></h4>
              </>
            )}

            {month_empty ?
              <h4 className="mt-5 mb-5"> {'Sorry no appointments available for this month. Please check future months.'}</h4>
              :
              <>
                {noSlotsFound &&
                  <>
                    {/* <h4 className="mt-5 font-size-24">{selectedDay}</h4>
                <br /> */}
                    <h4 className="mt-2 mb-5 font-size-18 line-height-24"> No appointments available for this date.<br />Check other dates or months.

                    </h4>
                  </>
                }
              </>
            }

            <div className="d-flex align-items-center flex-wrap mt-4 ml-2">
              <Slots
                is_group_session={is_group_session}
                sessionType={sessionType}
                practitionerInfo={practitionerInfo}
                activesession_id={activesession_id}
                mediumItems={mediumItems}
                user_type={user_type}
                client_id={client_id}
                setSlotsUpdated={setSlotsUpdated}
                slotsupdated={slotsupdated}
                activeSubSessionId={activeSubSessionId}
                setnoSlotsFound={setnoSlotsFound}
                bookingType={bookingType}
                type={type}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
