import React, { useEffect, useState } from "react";
import Filter from "../filter";
import "./search.css";
import Button from "../button";
import MultiDatePicker from 'react-multi-date-picker';
import {
  getCountry,
  getLanguages,
  getTimezones,
} from "../../services/uidata";
import {
  capatilizeLetter,
  filterVisibleTimeZones,
  screenNameHandler,
} from "../../utils/helpers";
import FilterOperator from "../filterOperator";
import FilterOptions from "../filterOption";
import { useDispatch } from "react-redux";
import { setCountryArray } from "../../reducer/uidataSlice";

export default function Search({ callApi, list, setFilterValues,
  setSearchValues, searchValue, filterValues, isDatepickerShow , isOperator = 0,setFilterOprater, showOprater,
  setShowOprater, filterOprater, operatorList, setOperatorList, optionsList, setOptionsList ,filterValueOptions,
  setFilterValueOptions, isAppointment, setServiceTypeShow,
  serviceTypeShow= false,}) {
  const [inputValue, setInputValue] = useState(searchValue !== null && searchValue !== undefined ? Object.values(searchValue)[0] : '');
  const [timer, setTimer] = useState(null);
  const [filterValue, setFilterValue] = useState(filterValues == undefined ? '' : filterValues);
 
 
  const [values, setValues] = useState([]);
  const [filterValueOprater, setFilterValueOprater] = useState("=");
  
  const [countryData, setCountryData] = useState([]);
  const [TimezoneData, setTimezoneData] = useState([]);
  const [formLanguages, setFormLanguages] = useState([]);

  const dispatch = useDispatch();
  
  useEffect(() => {
    updateTimeZone();
    updateCountries();
    updateLanguage();
    // console.log(filterValues,"filterValues");
    // setFilterOprater(filterValueOprater)
    console.log(operatorList, "operatorList");
  }, []);
  const updateCountries = () => {
    getCountry()
      .then((data) => {
        setCountryData(data.data.data);
        dispatch(setCountryArray(data.data.data));
        
      })
      .catch((err) => console.log(err));

  };
  const updateTimeZone = () => {
    getTimezones()
      .then((data) => {
        if (data.data.status) {
          setTimezoneData(filterVisibleTimeZones(data.data.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const updateLanguage = () => {
    getLanguages()
      .then((data) => {
        setFormLanguages(
          data.data.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    
    // console.log(searchValue, "searchValueGet")
    // console.log(values, "values")
      setInputValue(searchValue !== null && searchValue !== undefined && searchValue !== "" ? Object.values(searchValue)[0] : '');
      setValues(searchValue !== null && searchValue !== undefined && searchValue !== "" ? values : '');
  }, [searchValue])
  // useEffect(() => {
  //   // console.log(searchValue, "searchValue");
  //   setInputValue('');

  // }, [])
  const getValue = (obj) => {
    // Object.values(obj)[0];
    return obj !== null && obj !== undefined ? Object.values(obj)[0] : ''
  }
  const inputChanged = (e) => {
    setInputValue(e.target.value);

    let name = {};
    // if(isOperator ==1){
    //   setFilterOprater(filterValueOprater)
    // }
    if (filterValues === "") {
      name["screen_name"] = e.target.value;
    } else {
      name[filterValues] = e.target.value;
    }

    clearTimeout(timer);
    setSearchValues(name);
    let oprateVal = filterOprater;
    const newTimer = setTimeout(() => {
      if (e.target.value.length > 2 || e.target.value.length === 0) {
        callApi(name,oprateVal);
      }
    }, 2000);

    setTimer(newTimer);
  };
  const changeHandler = (e) => {
  if( setFilterValueOptions !== undefined){
    setFilterValueOptions("")
    
    if(isAppointment == 1 ){
         setServiceTypeShow(true)
     }
   let filterVal = e.target.value;
    if(filterVal == "screen_name" || filterVal == "legal_name" || filterVal == "email" || filterVal == "session_type" || filterVal == "name" || filterVal == "medium_email" || filterVal == "client_email") {
      setOperatorList(['=', 'LIKE']);
      setFilterValueOprater("LIKE");
      setFilterOprater("LIKE");
      if(isOperator ==1){
        setShowOprater(true);
      }
      if(isAppointment ==1){
        setShowOprater(false);
      }
      setOptionsList([ { value: '1', name: 'Yes' },
        { value: '0', name: 'No' }]);
        if(filterVal == "session_type" && isAppointment ==1){
          setServiceTypeShow(true)
        }
    } else if( filterVal == "id" || filterVal == "meeting_id" || filterVal == "referralCode") {
      setOperatorList(['=']);
      setFilterValueOprater("=");
      setFilterOprater("=");
      if(isOperator ==1){
          setShowOprater(true)
      }
      if(isAppointment ==1){
        setShowOprater(false);
      }
      setOptionsList([ { value: '1', name: 'Yes' },
        { value: '0', name: 'No' }]);
    } else if(filterVal == "phone" || filterVal == "whatsapp") {
      setOperatorList(['=', 'LIKE']);
      setFilterValueOprater("LIKE");
      setFilterOprater("LIKE");
      if(isOperator ==1){
          setShowOprater(true)
      }
      setOptionsList([ { value: '1', name: 'Yes' },
        { value: '0', name: 'No' }]);
    }else if(filterVal == "created_at" ) {
      setOperatorList(['=', 'BETWEEN']);
      setFilterValueOprater("=");
      setFilterOprater("=");
      setOptionsList([ { value: '1', name: 'Yes' },
        { value: '0', name: 'No' }]);
        if(isOperator ==1){
          setShowOprater(true)
        }
    } else if(filterVal == "country") {
      const formattedCountryData = countryData.map(country => ({
        value: isAppointment === 1 ? country.id : country.country_name,
        name: country.country_name
      }));
      setFilterValueOprater("=");
      setFilterOprater("=");
      setOptionsList(formattedCountryData)
      if(isOperator ==1){
        setShowOprater(false)
      }
    } else if(filterVal == "language") {
      const formattedformLanguages = formLanguages.map(language => ({
        value: language.id,
        name: language.name
      }));
      setOptionsList(formattedformLanguages)
      if(isOperator ==1){
        setShowOprater(false)
      }
    }else if(filterVal == "timezone") {
      setFilterValueOprater("=");
      setFilterOprater("=");
      const formattedTimezoneData = TimezoneData.map(timezone => ({
        value: timezone.id,
        name: timezone.front_name
      }));
      setOptionsList(formattedTimezoneData)
      if(isOperator ==1){
        setShowOprater(false)
      }
    }else {
      setFilterValueOprater("=");
      setFilterOprater("=");
      if(isOperator ==1){
        setShowOprater(false)
      }
      setOptionsList([ { value: '1', name: 'Yes' },
        { value: '0', name: 'No' }]);
    }
  }
    console.log(e.target.value)
    setFilterValue(e.target.value);
    // setInputValue("");
    setFilterValues(e.target.value);
  };
  const changeHandlerOprater = (e) => {
    setFilterValueOprater(e.target.value);
    setFilterOprater(e.target.value);
  };
  const handleValueChange = (newValues) => {
    // This function will be called when the date picker's value changes
    if(isOperator ==1){
      setFilterOprater(filterValueOprater)
    }
    if (newValues) {
      setValues(newValues);
      let name = {};
      if(newValues.length === 2){
        const startDate = newValues[0].format("YYYY-MM-DD");
        const endDate = newValues[1].format("YYYY-MM-DD");
        name[filterValue]  = `${startDate},${endDate}`; // Fix the assignment
      }else{
        const startDate = newValues[0].format("YYYY-MM-DD");
        name[filterValue] = `${startDate}`; // Fix the assignment
      }
      clearTimeout(timer);
      setSearchValues(name);
      const newTimer = setTimeout(() => {
        let oprateVal = ""
        if(filterValueOprater == "BETWEEN"){
          oprateVal = "BETWEEN";
        }else{
          oprateVal = "LIKE";
        }
        if(isAppointment == 1){
          callApi(name,"","",oprateVal);
        }else{
          callApi(name,oprateVal);
        }

      }, 2000);
      setTimer(newTimer);
    }
  };
  const changeHandlerOptions = (e) => {
    const selectedValue = e.target.value;
    setValues(selectedValue);
    setFilterValueOptions(selectedValue);
    setFilterOprater("=");
    
    let name = {};
    name[filterValues] = selectedValue;
    
    if (timer) {
        clearTimeout(timer);
    }
    console.log("name", name)
    
    setSearchValues((prevState) => {
      console.log("Previous State:", prevState); // Logs the previous state before the update
      return name;
    });
    
    

    const newTimer = setTimeout(() => {
        callApi(name,"=");
    }, 2000);

    setTimer(newTimer);
  };
  return (
    <div className="filter-wrapper">
      {isOperator == 1 && <><span className="selectOption"> Filter By:</span><Filter list={list} changeHandler={changeHandler} filterValue={filterValues} name={'Filter By'} /> {showOprater && <><span className="selectOption"> operator:</span>< FilterOperator operatorList={operatorList} changeHandlerOprater={changeHandlerOprater} filterOprater={filterOprater} name={'operator'} /></>} </>}
      {isDatepickerShow && isDatepickerShow === 1 ? (
        filterValues === "created_at" ? (
          
          <MultiDatePicker
            style={{ height: "45px" }}
            className="search-input"
            value={values}
            onChange={handleValueChange}
            range
            placeholder="Select date range"
            maxDate={new Date()}
          />
        ) : (
          <div className="search-box search-container">
             {((isOperator == 1 && showOprater) || (isAppointment == 1 && !showOprater)) &&((filterValues === "id" || 
               filterValues === "email" || 
               filterValues === "referralCode" || 
               filterValues === "screen_name" || 
               filterValues === "phone" || 
               filterValues === "whatsapp" || 
               filterValues === "session_type" || 
               filterValues === "name" || 
               filterValues === "medium_email" || 
               filterValues === "client_email" ||
               filterValues === "meeting_id" ||  
               filterValues === "legal_name")) ? (
                
                  <input
                    value={inputValue}
                    onChange={inputChanged}
                    className="search-input"
                    type={filterValues === "created_at" ? "date" : "text"}
                    placeholder="Search"
                  />
                
            ) : (
              showOprater === false && (
                <FilterOptions 
                  optionsList={optionsList} 
                  filterValueOptions={filterValueOptions}
                  changeHandlerOptions={changeHandlerOptions} 
                  name={'Select Option'} 
                />
              )
            )}
          
          {isOperator == 0 &&
            <input
              value={inputValue}
              onChange={inputChanged}
              className="search-input"
              type={filterValues === "created_at" ? "date" : "text"}
              placeholder="Search"
            />}
          </div>
        )
      ) : (
        <div className="search-box search-container">
          <input
            value={inputValue}
            onChange={inputChanged}
            className="search-input"
            type={filterValues === "created_at" ? "date" : "text"}
            placeholder="Search"
          />
        </div>
      )}

  {isOperator == 0 && <Filter list={list} changeHandler={changeHandler} filterValue={filterValues} name={'Filter By'} />}
    </div>
  );
}