import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../../../components/tabs";
import TabPanel from "../../../components/tabs/tabpanel";
import Upcoming from "./upcoming";
import Cancelled from "./cancelled";
import Completed from "./completed";

import { list } from "./data";
import Pusher from "pusher-js";

//
import {
  setAllAppointments,
  setAllUpcomingCount,
  setAllCompletedCount,
  setAllCancelledCount,
  setAllNoShowCount,
  setAllOngoingCount,
  setUpcomingAppointmentsPage,
  setUpcomingAppointmentsrowsPerPage,
  setCompletedAppointmentsPage,
  setCompletedAppointmentsrowsPerPage,
  setCancelledAppointmentsPage,
  setCancelledAppointmentsrowsPerPage,
  setNoShowAppointmentsPage,
  setNoShowAppointmentsrowsPerPage,
  setAllRestrictedCount,
  setEndedEarlyCount,
  setrestrictedAppointmentsPage,
  setrestrictedAppointmentsrowsPerPage,
  setendedEarlyAppointmentsPage,
  setendedEarlyAppointmentsrowsPerPage,
  setSessionTypes,
} from "../../../reducer/adminSlice";
import { setLoading } from "../../../reducer/auth/auth";

//service
import {
  getAllAppointments,
  changeAppointmentStatus,
  filterAllAppointments,
  getAllUpComingAppointments,
  getAllOngoingAppointments,
  getAllNoShowsAppointments,
  getAllUpCancelledAppointments,
  getAllCompletedAppointments,
  filterAllUpcomingAppointments,
  filterAllCompletedAppointments,
  filterAllCancelleddAppointments,
  filterAllNoShowAppointments,
  filterAllOngoingAppointments,
  getAllRestrictedAppointments,
  getAllEndedEarlyAppointments,
  filterAllRestrictedAppointments,
  filterAllEndedEarlyAppointments,
  exportAppointments,
  exportDownloadAppointments,
  getSessionType,
} from "../../../services/admin";

import "./appointments.css";
import NoShows from "./noshows";
import Ongoing from "./Ongoing";
import { setNewTimeZone } from "../../../services/medium";
import { setNewTimeZoneData } from "../../../reducer/userSlice";
import { setTimezoneArray } from "../../../reducer/uidataSlice";
import { getTimezones } from "../../../services/uidata";
import {
  emptyAllSelectBox,
  filterVisibleTimeZones,
} from "../../../utils/helpers";
import { toast } from "react-toastify";
import RestrictedAppointments from "./restricted";
import EndedEarlyAppointments from "./endedearly";
import { da } from "date-fns/locale";
import { items } from "../calendar/data";

export default function Appointments() {
  const {
    allUpcomingAppointments,
    allCompletedAppointments,
    allCancelledAppointments,
    allNoShowsAppointments,
    allOngoingAppointments,
    allUpcomingCount,
    allCompletedCount,
    allCancelledCount,
    allNoShowCount,
    allOngoingCount,
    globalRowsPerPage,
    upcomingAppointmentsPage,
    upcomingAppointmentsrowsPerPage,
    completedAppointmentsPage,
    completedAppointmentsrowsPerPage,
    cancelledAppointmentsPage,
    cancelledAppointmentsrowsPerPage,
    noshowAppointmentsPage,
    restrictedAppointmentsPage,
    restrictedAppointmentsrowsPerPage,
    endedEarlyAppointmentsPage,
    endedEarlyAppointmentsrowsPerPage,
    noshowAppointmentsrowsPerPage,
    ongoingAppointmentsPage,
    ongoingAppointmentsrowsPerPage,
    sessionTypes,
  } = useSelector((state) => state.users);
  const { timezone } = useSelector((state) => state.user.user);
  const { timezoneArray } = useSelector((state) => state.uidata);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [upcomingAppointment, setUpcomingAppointments] = useState([]);
  const [completedAppointment, setCompletedAppointments] = useState([]);
  const [canceledAppointment, setCanceledAppointments] = useState([]);
  const [showAppointment, setShowAppointments] = useState([]);
  const [restrictedAppontmets, setRestrictedAppontmets] = useState([]);
  const [endedEarlyAppontmets, setEndedEarlyAppontmets] = useState([]);
  const [showOngoingAppointments, setOngoingAppointments] = useState([]);

  const [listArray, setListArray] = useState(list);
  const [backuupcoming, setbackupUpcoming] = useState([]);
  const [backuupcompleted, setbackupCompleted] = useState([]);
  const [backuupcancelled, setbackupCancelled] = useState([]);
  const [backuupnoshow, setbackupUpNoShow] = useState([]);
  const [backuupnongoing, setbackupOngoing] = useState([]);
  const [timezoneData, setTimezoneData] = useState([]);

  const [filterValues, setFilterValues] = useState('screen_name');
  const [searchValue, setSearchValues] = useState('');
  const [searchValueFilter, setSearchValuesFilter] = useState('');

  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceFilterValue, setServiceFilterValue] = useState("");

  const [sortingNameOrderBy, setSortingNameOrderBy] = useState("");
  const [sortingTtabType, setSortingTabType] = useState("");
  const [meetingLinkRegenerated, setmeetingLinkRegenerated] = useState({})
  const [exportDownload, setExportDownload] = useState('');

  const [operatorList, setOperatorList] = useState(['=', 'LIKE']);
  const [optionsList, setOptionsList] = useState([ { value: '1', name: 'Yes' },
    { value: '0', name: 'No' }]);
  const [filterValueOptions, setFilterValueOptions] = useState("");
  const [showOprater, setShowOprater] = useState(false);
  const [filterOprater, setFilterOprater] = useState("=");
  const [serviceTypeShow, setServiceTypeShow] = useState(false);

  useEffect(() => {
    if (!sessionTypes.length) getSessionTypes();
    if (!timezoneArray.length) updateTimezones();
    launcApi();
    
  }, []);
  useEffect(() => {
    setTimezoneData(filterVisibleTimeZones(timezoneArray));
  }, [timezoneArray]);
  useEffect(() => {
    let info = sessionTypes.filter((data) => {
      if (parseInt(data.sub_session_id) !== 78) {
        return {
          ...data,
        };
      }
    })
    setServiceTypeList(info.map((data) => {
      return {
        value: data.sub_session_id,
        label: data.sub_session_name,

      }
    }));
  }, [sessionTypes]);
  //////
  const getSessionTypes = () => {
    getSessionType()
      .then((data) => {
        dispatch(setSessionTypes(data.data.data));
      })
      .catch(() => { });
  };
  const checkStatus0Values = (data) => {
    if (data.attendees !== undefined) {
      let array = data.attendees.filter((info) => info.status == 0);
      return array;
    } else {
      return [];
    }
    // return data.attendees.length;
  };
  const getUpComingAppointments = (data, activeTab) => {
    dispatch(setLoading(true));

    getAllUpComingAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setUpcomingAppointments(
          data.data.data.info.map((data) => {
            return {
              ...data,
              statusValue: "",
              nostatusValue: "",
              noOfAttendees: checkStatus0Values(data),
            };
          })
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getCompletedAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllCompletedAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setCompletedAppointments(
          data.data.data.info.map((data) => {
            return {
              ...data,
              statusValue: "",
              nostatusValue: "",
              noOfAttendees: checkStatus0Values(data),
            };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getCancelledAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllUpCancelledAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setCanceledAppointments(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getNoShowsAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllNoShowsAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setShowAppointments(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getRestrictedAppointments = (data, activeTab) => {
    dispatch(setLoading(true));

    getAllRestrictedAppointments(data, activeTab)
      .then((data) => {
        setRestrictedAppontmets(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getEndedEarlyAppointments = (data, activeTab) => {
    dispatch(setLoading(true));

    getAllEndedEarlyAppointments(data, activeTab)
      .then((data) => {
        setEndedEarlyAppontmets(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getOngoingAppointments = (data, activeTab) => {
    dispatch(setLoading(true));
    getAllOngoingAppointments(data, activeTab)
      .then((data) => {
        dispatch(setLoading(false));
        setOngoingAppointments(
          data.data.data.info.map((data) => {
            return { ...data, statusValue: "", nostatusValue: "" };
          })
        );
        dispatch(
          setAllUpcomingCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.upcoming)
            )
          )
        );
        dispatch(
          setAllCompletedCount(
            JSON.parse(
              JSON.stringify(data.data.data.appointments_count.completed)
            )
          )
        );
        dispatch(
          setAllCancelledCount(data.data.data.appointments_count.cancelled)
        );
        dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
        dispatch(setAllOngoingCount(data.data.data.appointments_count.ongoing));
        dispatch(
          setAllRestrictedCount(data.data.data.appointments_count.restrict)
        );
        dispatch(
          setEndedEarlyCount(data.data.data.appointments_count.endearly)
        );
        setListArray(
          list.map((item) => {
            if (item.name === "Upcoming") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.upcoming),
              };
            }
            if (item.name === "Completed") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.completed),
              };
            }

            if (item.name === "Cancelled") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.cancelled),
              };
            }

            if (item.name === "No Shows") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.noshow),
              };
            }
            if (item.name === "Restriced") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.restrict),
              };
            }
            if (item.name === "Ended Early") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.endearly),
              };
            }
            if (item.name === "Ongoing") {
              return {
                ...item,
                ...(item.count = data.data.data.appointments_count.ongoing),
              };
            }
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const filterserviceListValue = (list, id) => {
    let info = list.filter((e) => e.value === id);
    return info.length ? info[0].name : "";
  };
  /////
  const getPaginationData = (data, activeTab) => {
    console.log(
      data,
      activeTab,
      "data, activeTab",
      sortingNameOrderBy,
      sortingTtabType
    );
    if (activeTab + 1 === parseInt(sortingTtabType)) {
      Object.assign(data, sortingNameOrderBy);
    }
    let dat = {
      ...data,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
          session_type: searchValue !== undefined
          ? searchValue.session_type !== undefined
            ? searchValue.session_type
            : ""
          : "",
          session_id:   searchValue !== undefined
          ? searchValueFilter.session_id !== undefined
            ? searchValueFilter.session_id
            : ""
          : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at: searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      oprater: filterOprater !== undefined ? filterOprater : "",
      country: searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : ""
    };
    // getAllAppointmentsList
    // getUpComingAppointments(data,activeTab)
    if (activeTab === 0) {
      getUpComingAppointments(dat, activeTab);
    }
    if (activeTab === 1) {
      getCompletedAppointments(dat, activeTab);
    }
    if (activeTab === 2) {
      getCancelledAppointments(dat, activeTab);
    }
    if (activeTab === 3) {
      getNoShowsAppointments(dat, activeTab);
    }
    if (activeTab === 4) {
      getRestrictedAppointments(dat, activeTab);
    }
    if (activeTab === 5) {
      getEndedEarlyAppointments(dat, activeTab);
    }
    if (activeTab === 6) {
      getOngoingAppointments(dat, activeTab);
    }
  };

  const updateTimezones = () => {
    getTimezones()
      .then((data) => {
        dispatch(setTimezoneArray(data.data.data));
      })
      .catch((err) => console.log(err));
  };
  const changeTimeZoneHandler = (e) => {
    let id = e.target.value;
    let body = {
      timezone_id: id,
    };
    setNewTimeZone(body)
      .then((data) => {
        if (data.data.status) {
          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          getAllAppointmentsList(data, activeTab);
          dispatch(setNewTimeZoneData(filterTimeZone(id)));
        }
      })
      .catch(() => { });
  };
  const filterTimeZone = (id) => {
    let zone;
    timezoneData.filter((item) => {
      if (parseInt(id) === parseInt(item.id)) {
        zone = item.front_name;
      }
    });
    return zone;
  };
  useEffect(() => {
    downloadExport(activeTab);
    const data = {
      page: 1,
      rowsPerPage: 10,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type: searchValue !== undefined
      ? searchValue.session_type !== undefined
        ? searchValue.session_type
        : ""
      : "",
      session_id:   searchValue !== undefined
      ? searchValueFilter.session_id !== undefined
        ? searchValueFilter.session_id
        : ""
      : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at: searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      oprater: filterOprater !== undefined ? filterOprater : "",
      country: searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : "",
    };

    if (activeTab === 0) {
      getUpComingAppointments(data, activeTab);
    }
    if (activeTab === 1) {
      getCompletedAppointments(data, activeTab);
    }
    if (activeTab === 2) {
      getCancelledAppointments(data, activeTab);
    }
    if (activeTab === 3) {
      getNoShowsAppointments(data, activeTab);
    }
    if (activeTab === 4) {
      getRestrictedAppointments(data, activeTab);
    }
    if (activeTab === 5) {
      getEndedEarlyAppointments(data, activeTab);
    }
    if (activeTab === 6) {
      getOngoingAppointments(data, activeTab);
    }
    // getAllAppointmentsList(data, activeTab);

    // let resp = {
    //   upcoming: backuupcoming,
    //   completed: backuupcompleted,
    //   cancelled: backuupcancelled,
    //   show: backuupnoshow,
    //   ongoing: backuupnongoing,
    // };
    // dispatch(setAllAppointments(resp));
  }, [activeTab]);

  // useEffect(() => {
  //   setUpcomingAppointments(
  //     allUpcomingAppointments.map((data) => {
  //       return { ...data, statusValue: "", nostatusValue: "" };
  //     })
  //   );
  //   setCompletedAppointments(
  //     allCompletedAppointments.map((data) => {
  //       return { ...data, statusValue: "", nostatusValue: "" };
  //     })
  //   );
  //   setCanceledAppointments(allCancelledAppointments);
  //   setShowAppointments(allNoShowsAppointments);
  //   setOngoingAppointments(allOngoingAppointments);
  //   setListArray(
  //     list.map((item) => {
  //       if (item.name === "Upcoming") {
  //         return {
  //           ...item,
  //           ...(item.count = allUpcomingCount),
  //         };
  //       }
  //       if (item.name === "Completed") {
  //         return {
  //           ...item,
  //           ...(item.count = allCompletedCount),
  //         };
  //       }

  //       if (item.name === "Cancelled") {
  //         return {
  //           ...item,
  //           ...(item.count = allCancelledCount),
  //         };
  //       }

  //       if (item.name === "No Shows") {
  //         return {
  //           ...item,
  //           ...(item.count = allNoShowCount),
  //         };
  //       }
  //       if (item.name === "Ongoing") {
  //         return {
  //           ...item,
  //           ...(item.count = allOngoingCount),
  //         };
  //       }
  //     })
  //   );
  // }, [
  //   allCompletedAppointments,
  //   allUpcomingAppointments,
  //   allCancelledAppointments,
  //   allNoShowsAppointments,
  //   allOngoingAppointments,
  // ]);
  const getAllAppointmentsList = (paginationdata, activeTab, info) => {
    if (info === "refresh") {
      dispatch(setLoading(true));
    }
    let dat = {
      ...paginationdata,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type: serviceFilterValue !== "" ? filterserviceListValue(serviceTypeList, parseInt(serviceFilterValue)) : searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at: searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '',
      
    };
    
    let appointmentType = "";
    if (activeTab === 0) {
      appointmentType = "upcoming";
    }
    if (activeTab === 1) {
      appointmentType = "completed";
    }
    if (activeTab === 2) {
      appointmentType = "cancelled";
    }
    if (activeTab === 3) {
      appointmentType = "show";
    }
    if (activeTab === 4) {
      appointmentType = "ongoing";
    }
    getAllAppointments(dat, appointmentType)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          //

          //
          // dispatch();
          dispatch(setAllAppointments(data.data.data));
          setbackupUpcoming(
            JSON.parse(JSON.stringify(data.data.data.upcoming))
          );
          setbackupCompleted(
            JSON.parse(JSON.stringify(data.data.data.completed))
          );
          setbackupCancelled(
            JSON.parse(JSON.stringify(data.data.data.cancelled))
          );
          setbackupUpNoShow(JSON.parse(JSON.stringify(data.data.data.show)));
          setbackupOngoing(JSON.parse(JSON.stringify(data.data.data.ongoing)));

          dispatch(
            setAllUpcomingCount(data.data.data.upcoming_pagination.total)
          );

          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.upcoming_pagination.total)
              )
            )
          );

          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.completed_pagination.total)
              )
            )
          );
          dispatch(
            setAllCancelledCount(
              JSON.parse(
                JSON.stringify(data.data.data.cancelled_pagination.total)
              )
            )
          );
          dispatch(
            setAllNoShowCount(
              JSON.parse(JSON.stringify(data.data.data.show_pagination.total))
            )
          );
          dispatch(
            setAllOngoingCount(
              JSON.parse(
                JSON.stringify(data.data.data.ongoing_pagination.total)
              )
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const launcApi = () => {
    const data = {
      page: 1,
      rowsPerPage: 10,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type:
        serviceFilterValue !== ""
          ? filterserviceListValue(
            serviceTypeList,
            parseInt(serviceFilterValue)
          )
          : "",
      // session_type:
      //   searchValue !== undefined
      //     ? searchValue.session_type !== undefined
      //       ? searchValue.session_type
      //       : ""
      //     : "",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at:
        searchValue !== undefined && searchValue.created_at !== undefined
          ? searchValue.created_at
          : "",
    };
    getUpComingAppointments(data, activeTab);
    // if (
    //   !allUpcomingAppointments.length > 0 ||
    //   !allCompletedAppointments.length > 0 ||
    //   !allCancelledAppointments.length > 0 ||
    //   !allNoShowsAppointments.length > 0 ||
    //   !allOngoingAppointments.length > 0
    // ) {
    // getAllAppointmentsList(data, activeTab);
    // }
  };
  const changeStatus = (userId, status, appointmentId) => {
    let body = {
      userId: userId,
      status: status,
      appointmentId: appointmentId,
    };
    changeAppointmentStatus(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Status updated succesfully", {
            position: "top-center",
          });
          window.loadModal2("#ConfirmCancelAdmin", "hide");

          const data = {
            page: 1,
            rowsPerPage: 10,
          };
          if (activeTab === 0) {
            getUpComingAppointments(data, activeTab);
          } else if (activeTab === 1) {
            getCompletedAppointments(data, activeTab);
          }
          // getAllAppointmentsList(data, activeTab);
          emptyAllSelectBox();
        } else {
          toast.error("Something went wrong", { position: "top-center" });
        }
      })
      .catch(() => { });
  };
  const changeHandler = (e, status, appointmentId) => {
    let value = e.target.value;
    changeStatus(value, status, appointmentId);
  };
  const filterListAppointments = (constants,oprateVal) => {
    
    const {
      session_type = serviceFilterValue !== ""
        ? filterserviceListValue(serviceTypeList, parseInt(serviceFilterValue))
        : "",
      screen_name,
      orderBy = "asc",
      name,
      medium_email,
      client_email,
      meeting_id,
      country,
    } = constants; 
    let body = {
      session_type,
      screen_name,
      orderBy,
      name,
      medium_email,
      client_email,
      country,
      meeting_id,
    };
    filterAllAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(
            setAllUpcomingCount(data.data.data.upcoming_pagination.total)
          );

          dispatch(
            setAllCompletedCount(data.data.data.completed_pagination.total)
          );
          dispatch(
            setAllCancelledCount(data.data.data.cancelled_pagination.total)
          );
          dispatch(setAllNoShowCount(data.data.data.show_pagination.total));
          dispatch(setAllOngoingCount(data.data.data.ongoing_pagination.total));
          dispatch(setAllAppointments(data.data.data));
        }
      })
      .catch(() => { });
  };

  const filterListUpcomingAppointments = (constants, from, value,oprateVal) => {
    console.log(searchValue, "", filterValues, "", "")
    console.log(constants, "constat")
    console.log(searchValueFilter, "serviceFilterValue", from, "",value);
    
    if (constants !== undefined && constants.session_type !== undefined) {
      setServiceFilterValue('');
      setSearchValuesFilter('');
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
      created_at,country
    } = constants;
    let namdefault ="";
    let session_typesefault = "";
    let screen_namedefault = "";
    let medium_emaildefault = "";
    let client_emaildefault = "";
    let meeting_iddefault = "";
    let created_atdefault = "";
    let countrydefault = "";
    let session_iddefault = "";
    if((constants !== undefined &&  constants.orderBy) || (from !== undefined &&  from == "filter")){

     namdefault = constants.name == undefined ? searchValue !== undefined && searchValue.name !== undefined ? searchValue.name : '' : constants.name;
     session_typesefault = constants.session_type == undefined ? searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : '' : constants.session_type;
      screen_namedefault = constants.screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : constants.screen_name;
     medium_emaildefault = constants.medium_email == undefined ? searchValue !== undefined && searchValue.medium_email !== undefined ? searchValue.medium_email : '' : constants.medium_email;
     client_emaildefault = constants.client_email == undefined ? searchValue !== undefined && searchValue.client_email !== undefined ? searchValue.client_email : '' : constants.client_email;
     meeting_iddefault = constants.meeting_id == undefined ? searchValue !== undefined && searchValue.meeting_id !== undefined ? searchValue.meeting_id : '' : constants.meeting_id;
     created_atdefault = constants.created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : constants.created_at;
     countrydefault = constants.country == undefined ? searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : '' : constants.country;
    }
    if(from !== undefined &&  from == "filter"){
      session_typesefault = "";
    }
    if (constants !== undefined && constants.session_type) {
      session_iddefault = "";
    }else{
      session_iddefault = constants.session_id == undefined ? searchValueFilter !== undefined && searchValueFilter.session_id !== undefined ? searchValueFilter.session_id : '' : constants.session_id;
    }
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",

      session_type:  constants !== undefined
      ? constants.session_type !== undefined
        ? constants.session_type
        : session_typesefault
      : "",
      session_id:  constants !== undefined
      ? constants.session_id !== undefined
        ? constants.session_id
        : session_iddefault
      : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at: constants !== undefined
      ? constants.created_at !== undefined
        ? constants.created_at
        : created_atdefault
      : "",
      oprater: oprateVal !== undefined ? oprateVal : filterOprater,
      country: constants !== undefined
      ? constants.country !== undefined
        ? constants.country
        : countrydefault
      : "",
    };
    filterAllUpcomingAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setUpcomingAppointmentsPage(upcomingAppointmentsPage + 1));
          dispatch(setUpcomingAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          //
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );

          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );

          //
          setUpcomingAppointments(
            data.data.data.info.map((data) => {
              return {
                ...data,
                statusValue: "",
                nostatusValue: "",
                noOfAttendees: checkStatus0Values(data),
              };
            })
          );

          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };

  const filterListCompletedAppointments = (constants, from, value ,oprateVal) => {
   
    if (from !== 'filter'  && constants.session_type !== undefined) {
      setServiceFilterValue('');
      setSearchValuesFilter('');
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let namdefault ="";
    let session_typesefault = "";
    let screen_namedefault = "";
    let medium_emaildefault = "";
    let client_emaildefault = "";
    let meeting_iddefault = "";
    let created_atdefault = "";
    let countrydefault = "";
    let session_iddefault = "";
    if((constants !== undefined &&  constants.orderBy) || (from !== undefined &&  from == "filter")){
     screen_namedefault = constants.screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : constants.screen_name;
     namdefault = constants.name == undefined ? searchValue !== undefined && searchValue.name !== undefined ? searchValue.name : '' : constants.name;
     session_typesefault = constants.session_type == undefined ? searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : '' : constants.session_type;
     medium_emaildefault = constants.medium_email == undefined ? searchValue !== undefined && searchValue.medium_email !== undefined ? searchValue.medium_email : '' : constants.medium_email;
     client_emaildefault = constants.client_email == undefined ? searchValue !== undefined && searchValue.client_email !== undefined ? searchValue.client_email : '' : constants.client_email;
     meeting_iddefault = constants.meeting_id == undefined ? searchValue !== undefined && searchValue.meeting_id !== undefined ? searchValue.meeting_id : '' : constants.meeting_id;
     created_atdefault = constants.created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : constants.created_at;
     countrydefault = constants.country == undefined ? searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : '' : constants.country;
    }
    if(from !== undefined &&  from == "filter"){
      session_typesefault = "";
    }
    if (constants !== undefined && constants.session_type) {
      session_iddefault = "";
    }else{
      session_iddefault = constants.session_id == undefined ? searchValueFilter !== undefined && searchValueFilter.session_id !== undefined ? searchValueFilter.session_id : '' : constants.session_id;
    }
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
          session_type:  constants !== undefined
          ? constants.session_type !== undefined
            ? constants.session_type
            : session_typesefault
          : "",
          session_id:  constants !== undefined
      ? constants.session_id !== undefined
        ? constants.session_id
        : session_iddefault
      : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at: constants !== undefined
      ? constants.created_at !== undefined
        ? constants.created_at
        : created_atdefault
      : "",
      oprater: oprateVal !== undefined ? oprateVal : filterOprater,
      country: constants !== undefined
      ? constants.country !== undefined
        ? constants.country
        : countrydefault
      : "",
    };
    filterAllCompletedAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setCompletedAppointmentsPage(completedAppointmentsPage + 1));
          dispatch(setCompletedAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          setCompletedAppointments(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };
  const filterListCancelledAppointments = (constants, from, value ,oprateVal) => {
    if (from !== 'filter'  && constants.session_type !== undefined) {
      setServiceFilterValue('');
      setSearchValuesFilter('');
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let namdefault ="";
    let screen_namedefault = "";
    let session_typesefault = "";
    let medium_emaildefault = "";
    let client_emaildefault = "";
    let meeting_iddefault = "";
    let created_atdefault = "";
    let countrydefault = "";
    let session_iddefault = "";
    if((constants !== undefined &&  constants.orderBy) || (from !== undefined &&  from == "filter")){
     screen_namedefault = constants.screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : constants.screen_name;
     namdefault = constants.name == undefined ? searchValue !== undefined && searchValue.name !== undefined ? searchValue.name : '' : constants.name;
     session_typesefault = constants.session_type == undefined ? searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : '' : constants.session_type;
     medium_emaildefault = constants.medium_email == undefined ? searchValue !== undefined && searchValue.medium_email !== undefined ? searchValue.medium_email : '' : constants.medium_email;
     client_emaildefault = constants.client_email == undefined ? searchValue !== undefined && searchValue.client_email !== undefined ? searchValue.client_email : '' : constants.client_email;
     meeting_iddefault = constants.meeting_id == undefined ? searchValue !== undefined && searchValue.meeting_id !== undefined ? searchValue.meeting_id : '' : constants.meeting_id;
     created_atdefault = constants.created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : constants.created_at;
     countrydefault = constants.country == undefined ? searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : '' : constants.country;
    }
    if(from !== undefined &&  from == "filter"){
      session_typesefault = "";
    }
    if (constants !== undefined && constants.session_type) {
      session_iddefault = "";
    }else{
      session_iddefault = constants.session_id == undefined ? searchValueFilter !== undefined && searchValueFilter.session_id !== undefined ? searchValueFilter.session_id : '' : constants.session_id;
    }
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
          session_type:  constants !== undefined
          ? constants.session_type !== undefined
            ? constants.session_type
            : session_typesefault
          : "",
          session_id:  constants !== undefined
      ? constants.session_id !== undefined
        ? constants.session_id
        : session_iddefault
      : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at: constants !== undefined
      ? constants.created_at !== undefined
        ? constants.created_at
        : created_atdefault
      : "",
      oprater: oprateVal !== undefined ? oprateVal : filterOprater,
      country: constants !== undefined
      ? constants.country !== undefined
        ? constants.country
        : countrydefault
      : "",
    };
    filterAllCancelleddAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setCancelledAppointmentsPage(cancelledAppointmentsPage + 1));
          dispatch(setCancelledAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          setCanceledAppointments(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };
  const filterListNoShowdAppointments = (constants, from, value ,oprateVal) => {
    if (from !== 'filter'  && constants.session_type !== undefined) {
      setServiceFilterValue('');
      setSearchValuesFilter('');
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let namdefault ="";
    let session_typesefault = "";
    let screen_namedefault = "";
    let medium_emaildefault = "";
    let client_emaildefault = "";
    let meeting_iddefault = "";
    let created_atdefault = "";
    let countrydefault = "";
    let session_iddefault = "";
    if((constants !== undefined &&  constants.orderBy) || (from !== undefined &&  from == "filter")){
     screen_namedefault = constants.screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : constants.screen_name;
     namdefault = constants.name == undefined ? searchValue !== undefined && searchValue.name !== undefined ? searchValue.name : '' : constants.name;
     session_typesefault = constants.session_type == undefined ? searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : '' : constants.session_type;
     medium_emaildefault = constants.medium_email == undefined ? searchValue !== undefined && searchValue.medium_email !== undefined ? searchValue.medium_email : '' : constants.medium_email;
    let client_emaildefault = constants.client_email == undefined ? searchValue !== undefined && searchValue.client_email !== undefined ? searchValue.client_email : '' : constants.client_email;
     meeting_iddefault = constants.meeting_id == undefined ? searchValue !== undefined && searchValue.meeting_id !== undefined ? searchValue.meeting_id : '' : constants.meeting_id;
     created_atdefault = constants.created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : constants.created_at;
     countrydefault = constants.country == undefined ? searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : '' : constants.country;
    }
    if(from !== undefined &&  from == "filter"){
      session_typesefault = "";
    }
    if (constants !== undefined && constants.session_type) {
      session_iddefault = "";
    }else{
      session_iddefault = constants.session_id == undefined ? searchValueFilter !== undefined && searchValueFilter.session_id !== undefined ? searchValueFilter.session_id : '' : constants.session_id;
    }
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
          session_type:  constants !== undefined
          ? constants.session_type !== undefined
            ? constants.session_type
            : session_typesefault
          : "",
          session_id:  constants !== undefined
      ? constants.session_id !== undefined
        ? constants.session_id
        : session_iddefault
      : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at: constants !== undefined
      ? constants.created_at !== undefined
        ? constants.created_at
        : created_atdefault
      : "",
      oprater: oprateVal !== undefined ? oprateVal : filterOprater,
      country: constants !== undefined
      ? constants.country !== undefined
        ? constants.country
        : countrydefault
      : "",
    };
    filterAllNoShowAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(setNoShowAppointmentsPage(noshowAppointmentsPage + 1));
          dispatch(setNoShowAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));

          setShowAppointments(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
      })
      .catch(() => { });
  };
  const filterListRestrictedAppointments = (constants, from, value ,oprateVal) => {
    if (from !== 'filter'  && constants.session_type !== undefined) {
      setServiceFilterValue('');
      setSearchValuesFilter('');
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let namdefault ="";
    let session_typesefault = "";
    let screen_namedefault = "";
    let medium_emaildefault = "";
    let client_emaildefault = "";
    let meeting_iddefault = "";
    let created_atdefault = "";
    let countrydefault = "";
    let session_iddefault = "";
    if((constants !== undefined &&  constants.orderBy) || (from !== undefined &&  from == "filter")){
     screen_namedefault = constants.screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : constants.screen_name;
     namdefault = constants.name == undefined ? searchValue !== undefined && searchValue.name !== undefined ? searchValue.name : '' : constants.name;
     session_typesefault = constants.session_type == undefined ? searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : '' : constants.session_type;
     medium_emaildefault = constants.medium_email == undefined ? searchValue !== undefined && searchValue.medium_email !== undefined ? searchValue.medium_email : '' : constants.medium_email;
     client_emaildefault = constants.client_email == undefined ? searchValue !== undefined && searchValue.client_email !== undefined ? searchValue.client_email : '' : constants.client_email;
     meeting_iddefault = constants.meeting_id == undefined ? searchValue !== undefined && searchValue.meeting_id !== undefined ? searchValue.meeting_id : '' : constants.meeting_id;
     created_atdefault = constants.created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : constants.created_at;
     countrydefault = constants.country == undefined ? searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : '' : constants.country;
    }
    if(from !== undefined &&  from == "filter"){
      session_typesefault = "";
    }
    if (constants !== undefined && constants.session_type) {
      session_iddefault = "";
    }else{
      session_iddefault = constants.session_id == undefined ? searchValueFilter !== undefined && searchValueFilter.session_id !== undefined ? searchValueFilter.session_id : '' : constants.session_id;
    }
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
          session_type:  constants !== undefined
          ? constants.session_type !== undefined
            ? constants.session_type
            : session_typesefault
          : "",
          session_id:  constants !== undefined
      ? constants.session_id !== undefined
        ? constants.session_id
        : session_iddefault
      : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at: constants !== undefined
      ? constants.created_at !== undefined
        ? constants.created_at
        : created_atdefault
      : "",
      oprater: oprateVal !== undefined ? oprateVal : filterOprater,
      country: constants !== undefined
      ? constants.country !== undefined
        ? constants.country
        : countrydefault
      : "",
    };
    filterAllRestrictedAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(
            setrestrictedAppointmentsPage(restrictedAppointmentsPage + 1)
          );
          dispatch(setrestrictedAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          //
          setRestrictedAppontmets(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const filterListEndedEarlyAppointments = (constants, from, value,oprateVal) => {
    if (from !== 'filter'  && constants.session_type !== undefined) {
      setServiceFilterValue('');
      setSearchValuesFilter('');
    }
    const {
      session_type,
      screen_name,
      orderBy = "desc",
      order,
      name,
      medium_email,
      client_email,
      meeting_id,
    } = constants;
    let namdefault ="";
    let session_typesefault = "";
    let screen_namedefault = "";
    let medium_emaildefault = "";
    let client_emaildefault = "";
    let meeting_iddefault = "";
    let created_atdefault = "";
    let countrydefault = "";
    let session_iddefault = "";
    if((constants !== undefined &&  constants.orderBy) || (from !== undefined &&  from == "filter")){
     screen_namedefault = constants.screen_name == undefined ? searchValue !== undefined && searchValue.screen_name !== undefined ? searchValue.screen_name : '' : constants.screen_name;
     namdefault = constants.name == undefined ? searchValue !== undefined && searchValue.name !== undefined ? searchValue.name : '' : constants.name;
     session_typesefault = constants.session_type == undefined ? searchValue !== undefined && searchValue.session_type !== undefined ? searchValue.session_type : '' : constants.session_type;
     medium_emaildefault = constants.medium_email == undefined ? searchValue !== undefined && searchValue.medium_email !== undefined ? searchValue.medium_email : '' : constants.medium_email;
     client_emaildefault = constants.client_email == undefined ? searchValue !== undefined && searchValue.client_email !== undefined ? searchValue.client_email : '' : constants.client_email;
     meeting_iddefault = constants.meeting_id == undefined ? searchValue !== undefined && searchValue.meeting_id !== undefined ? searchValue.meeting_id : '' : constants.meeting_id;
     created_atdefault = constants.created_at == undefined ? searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : '' : constants.created_at;
     countrydefault = constants.country == undefined ? searchValue !== undefined && searchValue.country !== undefined ? searchValue.country : '' : constants.country;
    }
    if(from !== undefined &&  from == "filter"){
      session_typesefault = "";
    }
    if (constants !== undefined && constants.session_type) {
      session_iddefault = "";
    }else{
      session_iddefault = constants.session_id == undefined ? searchValueFilter !== undefined && searchValueFilter.session_id !== undefined ? searchValueFilter.session_id : '' : constants.session_id;
    }
    let body = {
      orderBy,
      order,
      screen_name:
        constants !== undefined
          ? constants.screen_name !== undefined
            ? constants.screen_name
            : screen_namedefault
          : "",
          session_type:  constants !== undefined
          ? constants.session_type !== undefined
            ? constants.session_type
            : session_typesefault
          : "",
          session_id:  constants !== undefined
      ? constants.session_id !== undefined
        ? constants.session_id
        : session_iddefault
      : "",
      // session_type:
      //   constants !== undefined
      //     ? constants.session_type !== undefined
      //       ? constants.session_type
      //       : ""
      //     : "",
      name:
        constants !== undefined
          ? constants.name !== undefined
            ? constants.name
            : namdefault
          : "",
      medium_email:
        constants !== undefined
          ? constants.medium_email !== undefined
            ? constants.medium_email
            : medium_emaildefault
          : "",
      client_email:
        constants !== undefined
          ? constants.client_email !== undefined
            ? constants.client_email
            : client_emaildefault
          : "",
      meeting_id:
        constants !== undefined
          ? constants.meeting_id !== undefined
            ? constants.meeting_id
            : meeting_iddefault
          : "",
      created_at: constants !== undefined
      ? constants.created_at !== undefined
        ? constants.created_at
        : created_atdefault
      : "",
      oprater: oprateVal !== undefined ? oprateVal : filterOprater,
      country: constants !== undefined
      ? constants.country !== undefined
        ? constants.country
        : countrydefault
      : "",
    };
    filterAllEndedEarlyAppointments(body)
      .then((data) => {
        if (data.data.status) {
          dispatch(
            setendedEarlyAppointmentsPage(endedEarlyAppointmentsPage + 1)
          );
          dispatch(setendedEarlyAppointmentsrowsPerPage(globalRowsPerPage));
          dispatch(setLoading(false));
          //
          setEndedEarlyAppontmets(
            data.data.data.info.map((data) => {
              return { ...data, statusValue: "", nostatusValue: "" };
            })
          );
          dispatch(
            setAllUpcomingCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.upcoming)
              )
            )
          );
          dispatch(
            setAllCompletedCount(
              JSON.parse(
                JSON.stringify(data.data.data.appointments_count.completed)
              )
            )
          );
          dispatch(
            setAllCancelledCount(data.data.data.appointments_count.cancelled)
          );
          dispatch(setAllNoShowCount(data.data.data.appointments_count.noshow));
          dispatch(
            setAllOngoingCount(data.data.data.appointments_count.ongoing)
          );
          dispatch(
            setAllRestrictedCount(data.data.data.appointments_count.restrict)
          );
          dispatch(
            setEndedEarlyCount(data.data.data.appointments_count.endearly)
          );
          setListArray(
            list.map((item) => {
              if (item.name === "Upcoming") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.upcoming),
                };
              }
              if (item.name === "Completed") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.completed),
                };
              }

              if (item.name === "Cancelled") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.cancelled),
                };
              }

              if (item.name === "No Shows") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.noshow),
                };
              }
              if (item.name === "Restriced") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.restrict),
                };
              }
              if (item.name === "Ended Early") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.endearly),
                };
              }
              if (item.name === "Ongoing") {
                return {
                  ...item,
                  ...(item.count = data.data.data.appointments_count.ongoing),
                };
              }
            })
          );
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  // const filterListOngoingAppointments = (constants) => {
  //   const {
  //     session_type,
  //     screen_name,
  //     orderBy = "asc",
  //     name,
  //     medium_email,
  //     client_email,
  //     meeting_id,
  //   } = constants;
  //   let body = {
  //     session_type,
  //     screen_name,
  //     orderBy,
  //     name,
  //     medium_email,
  //     client_email,
  //     meeting_id,
  //   };
  //   filterAllOngoingAppointments(body)
  //     .then((data) => {
  //       if (data.data.status) {
  //         dispatch(setCompletedAppointmentsPage(2));
  //         dispatch(setCompletedAppointmentsrowsPerPage(10));
  //         dispatch(setLoading(false));
  //         setOngoingAppointments(
  //           data.data.data.info.map((data) => {
  //             return { ...data, statusValue: "", nostatusValue: "" };
  //           })
  //         );
  //         dispatch(
  //           setAllUpcomingCount(
  //             JSON.parse(
  //               JSON.stringify(data.data.data.appointments_count.upcoming)
  //             )
  //           )
  //         );
  //         dispatch(
  //           setAllCompletedCount(
  //             JSON.parse(
  //               JSON.stringify(data.data.data.appointments_count.completed)
  //             )
  //           )
  //         );
  //         setListArray(
  //           list.map((item) => {
  //             if (item.name === "Upcoming") {
  //               return {
  //                 ...item,
  //                 ...(item.count = data.data.data.appointments_count.upcoming),
  //               };
  //             }
  //             if (item.name === "Completed") {
  //               return {
  //                 ...item,
  //                 ...(item.count = data.data.data.appointments_count.completed),
  //               };
  //             }

  //             if (item.name === "Cancelled") {
  //               return {
  //                 ...item,
  //                 ...(item.count =  data.data.data.appointments_count.cancelled),
  //               };
  //             }

  //             if (item.name === "No Shows") {
  //               return {
  //                 ...item,
  //                 ...(item.count =  data.data.data.appointments_count.noshow),
  //               };
  //             }
  //             if (item.name === "Ongoing") {
  //               return {
  //                 ...item,
  //                 ...(item.count =  data.data.data.appointments_count.ongoing),
  //               };
  //             }
  //           })
  //         );
  //       }
  //     })
  //     .catch(() => {});
  // };

  const changeHandlerFilter = (e, filterName) => {
    // setFilterValues('screen_name')
    // setSearchValues({ screen_name: '' })
    let value = e;
    let multiSessionType = '';
    //setSearchValues(value)
    if (Array.isArray(value)) {
      // Check if "other" exists in the selected values
      //setServiceFilterValue("");
      multiSessionType = value.map(item => item.value).join(',');
  } 
  // Single selection
  else if (value) {
      multiSessionType = value.value; // Store single value
  }
 if(searchValue !==undefined && searchValue.session_type){
  setSearchValues("");
 }
 setSearchValuesFilter({session_id: multiSessionType})
  console.log("Multi Session Type:", multiSessionType); // Will show like: "1,3,4"
    setServiceFilterValue(value)
    if (filterName === 'upcoming') {
      filterListUpcomingAppointments({ session_id: multiSessionType }, 'filter', value);
    } else if (filterName === 'completely') {
      filterListCompletedAppointments({ session_id: multiSessionType }, 'filter', value);
    } else if (filterName === 'cancelled') {
      filterListCancelledAppointments({ session_id: multiSessionType }, 'filter', value);
    }
    else if (filterName === 'noshows') {
      filterListNoShowdAppointments({ session_id: multiSessionType }, 'filter', value);
    }
    else if (filterName === 'restricted') {
      filterListRestrictedAppointments({ session_id: multiSessionType }, 'filter', value);
    }
    else if (filterName === 'endedearly') {
      filterListEndedEarlyAppointments({ session_id: multiSessionType }, 'filter', value);
    }
    // else if (filterName === 'ongoing') {
    //   (searchValue, 'filter', value);
    // }
  };
  const filterReset = () => {
    setServiceFilterValue("");
    setFilterValues("screen_name");
    setSearchValues("");
    let data = {
      "page": 1,
      "rowsPerPage": 10,
      "session_type": "",
      "medium_name": "",
      "name": "",
      "medium_email": "",
      "client_email": "",
      "meeting_id": "",
      "created_at": "",
      "orderBy": "",
      "order": "",
      
    };
    if (activeTab === 0) {
      getUpComingAppointments(data, activeTab);
    }
    if (activeTab === 1) {
      getCompletedAppointments(data, activeTab);
    }
    if (activeTab === 2) {
      getCancelledAppointments(data, activeTab);
    }
    if (activeTab === 3) {
      getNoShowsAppointments(data, activeTab);
    }
    if (activeTab === 4) {
      getRestrictedAppointments(data, activeTab);
    }
    if (activeTab === 5) {
      getEndedEarlyAppointments(data, activeTab);
    }
    if (activeTab === 6) {
      getOngoingAppointments(data, activeTab);
    }
  };

  useEffect(() => {
    Pusher.logToConsole = true;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("zoom-link");
    channel.bind("App\\Events\\ZoomLinkUpdate", function (data) {

      if (data.appointment_id && data.meeting_link) {
        setmeetingLinkRegenerated(data)
      }
    });
  }, []);

  useEffect(() => {
    console.log(upcomingAppointment, "upcomingAppointment")
    if (meetingLinkRegenerated.appointment_id && meetingLinkRegenerated.meeting_link) {
      let mapin = upcomingAppointment.map((info) => {
        if (info.id == meetingLinkRegenerated.appointment_id) {
          return {
            ...info,
            meeting_link: meetingLinkRegenerated.meeting_link,
          };
        } else {
          return {
            ...info,
          };
        }
      });
      console.log(mapin, "mapin");
      setUpcomingAppointments(mapin);
    }

  }, [meetingLinkRegenerated])
  const exportTable = (appointmentType,appoitmentValue) => {

    let body = {
      appointment_type: appointmentType,
        appoitment_value: appoitmentValue,
      page: 1,
      rowsPerPage: 10,
      screen_name:
        searchValue !== undefined
          ? searchValue.screen_name !== undefined
            ? searchValue.screen_name
            : ""
          : "",
      session_type: searchValue !== undefined
          ? searchValue.session_type !== undefined
            ? searchValue.session_type
            : ""
          : "",
      session_id:"",
      name:
        searchValue !== undefined
          ? searchValue.name !== undefined
            ? searchValue.name
            : ""
          : "",
      medium_email:
        searchValue !== undefined
          ? searchValue.medium_email !== undefined
            ? searchValue.medium_email
            : ""
          : "",
      client_email:
        searchValue !== undefined
          ? searchValue.client_email !== undefined
            ? searchValue.client_email
            : ""
          : "",
      meeting_id:
        searchValue !== undefined
          ? searchValue.meeting_id !== undefined
            ? searchValue.meeting_id
            : ""
          : "",
      created_at: searchValue !== undefined && searchValue.created_at !== undefined ? searchValue.created_at : ''
    };
    exportAppointments(body)
      .then((data) => {
        if (data.data.status) {
          toast.success("Export request send successfully!!", {
            position: "top-center",
          });
        } else {
          toast.error("Something went wrong", { position: "top-center" });
        }
      })
      .catch(() => { });
  }
  
  const downloadExport = (activeTab) => {
    let Tab = "upcoming";
    let apaointmentValue = 0;
    if (activeTab === 0) {
        Tab = "upcoming";
        apaointmentValue =0;
    }
    if (activeTab === 1) {
      Tab = "completed";
      apaointmentValue=1
    }
    if (activeTab === 2) {
      Tab = "cancelled";
        apaointmentValue =3;
    }
    if (activeTab === 3) {
     
      Tab = "show";
        apaointmentValue =2;
    }
    if (activeTab === 4) {
      Tab = "restrict";
        apaointmentValue =5;
    }
    if (activeTab === 5) {
      Tab = "endearly";
        apaointmentValue =4;
    }
    if (activeTab === 6) {
      Tab = "ongoing";
        apaointmentValue =0;
    }
    let body = {
      appoitment_type: Tab
    }
    
    exportDownloadAppointments(body)
    .then((data) => {
      if (data.data.status) {
        console.log(data.data.data)
        setExportDownload(data.data.data);
      } else {
       // toast.error("Something went wrong", { position: "top-center" });
      }
    })
    .catch(() => { });
    //setExportDownload
  }

  return (
    <div className="all-medium-page">
      <div className="middle-screen-name time-zone-area font-size-22 mt-2 mb-2">
        {" "}
        <select
          onChange={changeTimeZoneHandler}
          className="select-box-timezone pointer"
        >
          <option disabled selected value="">
            Change Timezone
          </option>
          {timezoneData.map((item) => (
            <option value={item.id}>{item.front_name}</option>
          ))}
        </select>
        <b>
          Timezone: {timezone !== undefined && timezone}&nbsp; { }
        </b>
      </div>
      <Tabs active={activeTab} list={listArray} setActiveTab={setActiveTab} />
      <ul className="tabs-content">
        {console.log(upcomingAppointment, "upcomingAppointment")}
        <TabPanel id={0} activeTab={activeTab}>
          <Upcoming
            upcomingAppointments={upcomingAppointment}
            changeHandler={changeHandler}
            filterListAppointments={filterListUpcomingAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            getAllAppointmentsList={getUpComingAppointments}
            activeTab={activeTab}
            upcomingAppointmentsPage={upcomingAppointmentsPage}
            upcomingAppointmentsrowsPerPage={upcomingAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            
            setOptionsList={setOptionsList}
            optionsList={optionsList}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setFilterValueOptions={setFilterValueOptions}
            filterValueOptions={filterValueOptions}
            setServiceTypeShow={setServiceTypeShow}
            serviceTypeShow={serviceTypeShow}
            exportTable={exportTable}
            exportDownload={exportDownload}
          />
        </TabPanel>

        <TabPanel id={1} activeTab={activeTab}>
          <Completed
            completedAppointments={completedAppointment}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListCompletedAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            getAllAppointmentsList={getCompletedAppointments}
            activeTab={activeTab}
            completedAppointmentsPage={completedAppointmentsPage}
            completedAppointmentsrowsPerPage={completedAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}

            setOptionsList={setOptionsList}
            optionsList={optionsList}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setFilterValueOptions={setFilterValueOptions}
            filterValueOptions={filterValueOptions}
            setServiceTypeShow={setServiceTypeShow}
            serviceTypeShow={serviceTypeShow}
            exportTable={exportTable}
            exportDownload={exportDownload}
          />
        </TabPanel>
        <TabPanel id={2} activeTab={activeTab}>
          <Cancelled
            canceledAppointment={canceledAppointment}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListCancelledAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            cancelledAppointmentsPage={cancelledAppointmentsPage}
            cancelledAppointmentsrowsPerPage={cancelledAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getCancelledAppointments}
            activeTab={activeTab}

            setOptionsList={setOptionsList}
            optionsList={optionsList}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setFilterValueOptions={setFilterValueOptions}
            filterValueOptions={filterValueOptions}
            setServiceTypeShow={setServiceTypeShow}
            serviceTypeShow={serviceTypeShow}
            exportTable={exportTable}
            exportDownload={exportDownload}
          />
        </TabPanel>
        <TabPanel id={3} activeTab={activeTab}>
          <NoShows
            showAppointment={showAppointment}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListNoShowdAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            noshowAppointmentsPage={noshowAppointmentsPage}
            noshowAppointmentsrowsPerPage={noshowAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getNoShowsAppointments}
            activeTab={activeTab}

            setOptionsList={setOptionsList}
            optionsList={optionsList}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setFilterValueOptions={setFilterValueOptions}
            filterValueOptions={filterValueOptions}
            setServiceTypeShow={setServiceTypeShow}
            serviceTypeShow={serviceTypeShow}
            exportTable={exportTable}
            exportDownload={exportDownload}
          />
        </TabPanel>
        <TabPanel id={4} activeTab={activeTab}>
          <RestrictedAppointments
            showAppointment={restrictedAppontmets}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={filterListRestrictedAppointments}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            restrictedAppointmentsPage={restrictedAppointmentsPage}
            restrictedAppointmentsrowsPerPage={
              restrictedAppointmentsrowsPerPage
            }
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getRestrictedAppointments}
            activeTab={activeTab}

            setOptionsList={setOptionsList}
            optionsList={optionsList}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setFilterValueOptions={setFilterValueOptions}
            filterValueOptions={filterValueOptions}
            setServiceTypeShow={setServiceTypeShow}
            serviceTypeShow={serviceTypeShow}
            exportTable={exportTable}
            exportDownload={exportDownload}
          />
        </TabPanel>
        <TabPanel id={5} activeTab={activeTab}>
          <EndedEarlyAppointments
            showAppointment={endedEarlyAppontmets}
            changeHandler={changeHandler}
            filterListAppointments={filterListEndedEarlyAppointments}
            filterReset={filterReset}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            endedEarlyAppointmentsPage={endedEarlyAppointmentsPage}
            endedEarlyAppointmentsrowsPerPage={
              endedEarlyAppointmentsrowsPerPage
            }
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}
            getAllAppointmentsList={getEndedEarlyAppointments}
            activeTab={activeTab}

            setOptionsList={setOptionsList}
            optionsList={optionsList}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setFilterValueOptions={setFilterValueOptions}
            filterValueOptions={filterValueOptions}
            setServiceTypeShow={setServiceTypeShow}
            serviceTypeShow={serviceTypeShow}
            exportTable={exportTable}
            exportDownload={exportDownload}
          />
        </TabPanel>

        <TabPanel id={6} activeTab={activeTab}>
          <Ongoing
            showOngoingAppointments={showOngoingAppointments}
            changeHandler={changeHandler}
            filterReset={filterReset}
            filterListAppointments={() => { }}
            getPaginationData={(data) => getPaginationData(data, activeTab)}
            setFilterValues={setFilterValues}
            setSearchValues={setSearchValues}
            ongoingAppointmentsPage={ongoingAppointmentsPage}
            ongoingAppointmentsrowsPerPage={ongoingAppointmentsrowsPerPage}
            searchValue={searchValue}
            filterValues={filterValues}
            serviceTypeList={serviceTypeList}
            changeHandlerFilter={changeHandlerFilter}
            serviceFilterValue={serviceFilterValue}
            setSortingNameOrderBy={setSortingNameOrderBy}
            setSortingTabType={setSortingTabType}

            setOptionsList={setOptionsList}
            optionsList={optionsList}
            operatorList={operatorList}
            setOperatorList={setOperatorList}
            showOprater={showOprater}
            setShowOprater={setShowOprater}
            setFilterOprater={setFilterOprater}
            filterOprater={filterOprater}
            setFilterValueOptions={setFilterValueOptions}
            filterValueOptions={filterValueOptions}
            setServiceTypeShow={setServiceTypeShow}
            serviceTypeShow={serviceTypeShow}
            exportTable={exportTable}
            exportDownload={exportDownload}
          />
        </TabPanel>
      </ul>
      {/* <Pagination  rowsPerPageOptions={[5, 10, 25]}  count={100} getPaginationData={getPaginationData} /> */}
    </div>
  );
}
