import API from "../api/api";

const config = (token) => {
  return {
    headers: {
      "Content-type": "application/json",

      "X-Frame-Options": "DENY",
      "Content-Security-Policy": "style-src 'self'",
      Authorization: `Bearer ${token}`,
    },
  };
};
export const acceptSession = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("accept-session", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const setMediumOnlineStatus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("set-online-offline", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMediumAvailability = async (body) => {
  let token = localStorage.getItem("access_token");
  let url =
    body.medium_id !== undefined
      ? `get-new-appointment-list?from_date=${body.startDate}&to_date=${body.endDate}&medium_id=${body.medium_id}`
      : `get-new-appointment-list?from_date=${body.startDate}&to_date=${body.endDate}`;
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setMediumAvailability = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "set-medium-availability",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMediumAppointments = async (param) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    param ? `get-medium-appointments/${param}` : `get-medium-appointments`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMediumAppointmentCount = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-medium-appointment-status`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getProfileBasicInfo = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`profile-info`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAdvancedPreferencesAdmin = async (param) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-medium-appointments/${param}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getAdvancedPreferences = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-default-preferences`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getAdvancedPreferencesFromAdmin = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-default-preferences/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getProfilePreferences = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`my-preference-data`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getProfileTechnology = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`my-tech-data`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getProfileLeagalAndPrivacy = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-medium-legal-privacy`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAllAppointments = async (data, appointmentType) => {
  console.log("paginatedData", data);
  console.log("appointmentType", appointmentType);
  let token = localStorage.getItem("access_token");
  let page = data !== undefined ? data.page : 1;
  let rowsPerPage = data !== undefined ? data.rowsPerPage : 10;
  let session_type = data !== undefined && data.session_type !== undefined ? data.session_type : '';
  let screen_name = data !== undefined && data.screen_name !== undefined ? data.screen_name : '';
  let meeting_id = data !== undefined && data.meeting_id !== undefined ? data.meeting_id : '';



  const response = await API.get(
    `get-all-type-appointments?page=${page}&appoitment_type=${appointmentType}&rowsPerPage=${rowsPerPage}&session_type=${session_type}&name=${screen_name}&meeting_id=${meeting_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const filterAllAppointments = async (body) => {
  const { session_type = "", screen_name = "", meeting_id = "" } = body;

  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-all-type-appointments?session_type=${session_type}&name=${screen_name}&meeting_id=${meeting_id}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const changeAppointmentStatus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "change-medium-status-appointment",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const changeAppointmentStatusPractice = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "practice-session/no-show",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getFeedbacks = async (body) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-all-feedbacks`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getPracticeFeedbacks = async (type, subsession_id) => {
  let token = localStorage.getItem("access_token");
  let url = subsession_id !== undefined ? `get-all-feedbacks?session_type=${type}&subsession_id=${subsession_id}` : `get-all-feedbacks?session_type=${type}`
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const updateProfilePicture = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-profile-picture", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const setNewTimeZone = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("update-user-time-zone", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getLinkedEmail = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`user-linked-emails`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getLinkedPaymentToken = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-payment-gateways`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const fetchLinkedCalendar = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-all-google-booking-calender`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const fetchLinkedZoomAccount = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-all-zoom-booking-calender`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const checkZoomConnectionStatus = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`check-zoom-connection`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const publishFeedback = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("approve-feedback", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const updateGoogleCalendar = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "update-google-calendar_id",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const savePaymentMedtods = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "save-gateway-credentials",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const savePaymentToForward = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "save-pay-forward",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getTransactionDetails = async (body) => {
  console.log("cde", body);
  let token = localStorage.getItem("access_token");

  let name = body.name !== undefined ? body.name : "";
  let newId = body.newId !== undefined ? body.newId : "";
  let screen_name = body.screen_name !== undefined ? body.screen_name : "";
  let payment_method =
    body.payment_method !== undefined ? body.payment_method : "";
  let payment_status =
    body.payment_status !== undefined ? body.payment_status : "";
  let session_name = body.session_name !== undefined ? body.session_name : "";
  let page = body.page !== undefined ? body.page : 1;
  let rowsPerPage = body.rowsPerPage !== undefined ? body.rowsPerPage : 10;
  console.log("mediumid", body.medium_id);
  let url =
    body !== undefined && body.medium_id !== undefined
      ? `get-all-transactions?medium_id=${body.medium_id}`
      : `get-all-transactions?page=${page}&rowsPerPage=${rowsPerPage}&medium_name=${screen_name}&session_name=${session_name}&client_name=${name}&payment_method=${payment_method}&payment_status=${payment_status}&session_id=${newId}`;
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const resendSurveyEmail = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post("send-survey-mail", body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const updateGoogleSyncSettings = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "update-google-sync-settings",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const checkAlreadyExistService = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "check-medium-availability",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const verifyPaymentOtp = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    "send-payment-gateway-otp",
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getServicesList = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-services-list?type=services`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getServicesType = async (name) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-services-list?type=services_type&q=${name}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getServicesLength = async (id, is_group_session) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(
    `get-services-list?type=session_list&service=${id}&is_group_session=${is_group_session ? 1 : 0}`,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const checkIfFreeSessions = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`coaching/check-free-booking`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getMatchingMediums = async (value, partner_level) => {
  let body = {
    q: value,
    partner_level: partner_level,
  }
  let token = localStorage.getItem("access_token");

  const response = await API.post(
    `search/matching-mediums`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getDateCountMediumGroupCoahing = async (body) => {
  console.log(body, "datada");
  let token = localStorage.getItem("access_token");
  let url = `group/coaching/get-medium-calendar?from=${body.startDate}&to=${body.endDate}&medium_id=${body.medium_id !== undefined ? body.medium_id : ""}&service=${body.service}&session_length=${body.session_length}`;
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getDateCountMedium = async (body) => {
  console.log(body, "datada");
  let token = localStorage.getItem("access_token");
  let url = `get-${body.service === 2 ? 'coaching' : body.service === 1 ? 'practice' : 'support'}-availability?from=${body.startDate}&to=${body.endDate}&medium_id=${body.medium_id !== undefined ? body.medium_id : ""}&service=${body.service}&session_length=${body.session_length}`;
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const checkPracticeAvailability = async (info) => {
  let body;
  if (info.medium_id !== undefined) {
    body = {
      date: info.date,
      time: info.time,
      service: info.service,
      session_length: info.session_length,
      medium_id: info.medium_id === undefined ? "" : info.medium_id,
    }
  }
  else {
    body = {
      date: info.date,
      time: info.time,
      service: info.service,
      session_length: info.session_length,
      partner_level: info.partner_level
    }
  }


  let token = localStorage.getItem("access_token");
  let url = `check-practice-availability`
  const response = await API.post(url, body, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
// export const checkPracticeAvailability = async (body) => {
//   console.log(body, "datada");
//   let token = localStorage.getItem("access_token");
//   let url = body.medium_id !== undefined ? `check-practice-availability?date=${body.date}&time=${body.time}&service=${body.service}&session_length=${body.session_length}&medium_id=${body.medium_id === undefined ? "" : body.medium_id}`
//     : `check-practice-availability?date=${body.date}&time=${body.time}&service=${body.service}&session_length=${body.session_length}&partner_level=${body.partner_level}`;
//   const response = await API.get(url, config(token))
//     .then((data) => {
//       return data;
//     })
//     .catch((err) => {
//       return err;
//     });
//   return response;
// };

export const getSlotsByDateMedium = async (
  { date,
    user_type,
    session_id,
    medium_id,
    subsession_id = "",
    is_medium_list,
    time }
) => {
  console.log(session_id);
  let url = `${session_id === 2 ? 'coaching' : session_id === 1 ? 'practice' : 'support'}/get-slots-by-date?date=${date}&session_id=${session_id !== "" && session_id !== undefined ? session_id : "5"
    }&medium_id=${medium_id}&subsession_id=${subsession_id}&time=${time}&is_medium_list=true`

  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const getSlotsByDateMediumGroupCoaching = async (
  { date,
    user_type,
    session_id,
    medium_id,
    subsession_id = "",
    is_medium_list,
    time }
) => {

  let url = `group/coaching/get-medium-slots-by-date?date=${date}&session_id=${session_id}&medium_id=${medium_id}&subsession_id=${subsession_id}&time=${time}&is_medium_list=true`

  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAvailableMediumsForGroupCoaching = async (
  { service,
    session_length
  }
) => {

  let url = `group/coaching/get-available-mediums?service=${service}&session_length=${session_length}`

  let token = localStorage.getItem("access_token");
  const response = await API.get(url, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getCoachingMediumList = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `coaching/get-mediums`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const createMediumBooking = async (body, type) => {

  let token = localStorage.getItem("access_token");
  const response = await API.post(
    type ?
      `group/coaching/create-appointment`
      :
      `create-${body.service === 2 ? 'coaching' : body.service === 1 ? 'practice' : 'support'}-appointment`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getMediumBookingDetails = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `coaching/get-booking-medium`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const verifyZoom = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `verify-zoom`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const verifyCalendar = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `verify-calendar`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const creatDummyAppointment = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `create-dummy-appointment`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const completeMediumOnBoarding = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `medium-onboarding`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const saveStepsProgress = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `save-step-progress`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const blockUserfromMedium = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `block-user-pair`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const cancelGroupAppointments = async (body, attendee) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    attendee ? `group-sessions/cancellation` :
      `group-sessions/booking-medium/remove`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const noshowGroupAppointments = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `group-sessions/no-show`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const noshowRemoveGroupAppointments = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `group-sessions/remove`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const syncGoogleCalendarFinishSetup = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `google-calendar-sync`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const syncGoogleCalendarFinishSetupConfirmation = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `google-calendar-sync-confirmation`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

////on demand practice

export const getMediumOndemandEvents = async (from, to, keyword = "",session_id="") => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-on-demand-session-slots?from=${from}&to=${to}&keyword=${keyword}&session_type=${session_id} `, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const checkMediumInOnDemandQueue = async () => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`check-medium-in-on-demand-queue`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};



export const addMediumToOnDemandQueue = async (event_id, date, end_time, user_pereference) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`add-medium-to-on-demand-queue?event_id=${event_id}&date=${date}&end_time=${end_time}&user_pereference=${user_pereference}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const removeMediumFromOnDemandQueue = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`remove-medium-from-on-demand-queue?event_id=${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};



export const bookOnDemandSession = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `book-on-demand-session`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}

export const setReminderOndemand = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `set-ondemand-session-reminder`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}


export const getZoomLinkOnDemand = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-appointment-zoom-link/${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}


export const addMediumToOnDemandQueueBackFill = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `add-medium-in-backfill-queue`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const removeMediumFromOnDemandQueueBackFill = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `remove-medium-from-backfill-queue`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}

export const saveMediumBackfillSettings = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `save-medium-backfill-settings`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const rejectOnDemandSession = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `reject-on-demand-session`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}




export const acceptOnDemandSession = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `accept-on-demand-session`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const createOnDemandBooking = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `create-on-demand-booking`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}


export const clearOnDemandCache = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`clear-on-demand-cache/${id}`, config(token))
  .then((data) => {
    return data;
  })
  .catch((err) => {
    return err;
  });
return response;
};

export const getMediumBackfillSettings = async (id) => {
  let token = localStorage.getItem("access_token");

  const response = await API.get(`get-medium-backfill-settings?id=${id}`, config(token))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};


export const removePairFromCache = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `remove-pair-from-cache`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
export const updateOnDemandBoxStatus = async (body) => {
  let token = localStorage.getItem("access_token");
  const response = await API.post(
    `update-on-demand-box-status`,
    body,
    config(token)
  )
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
